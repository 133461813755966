import React from 'react';
import { Link } from 'react-router-dom';
import CodeIcon from './CodeIcon'; // Assuming the custom icon component is saved as CodeIcon.tsx

const BottomNavBar: React.FC = () => {
  return (
    <nav className="fixed bottom-4 left-0 right-0 flex justify-center">
      <div className="flex items-center justify-between bg-white rounded-full px-6 py-2 w-72 shadow-lg">
        {/* Home Icon */}
        <Link to="/" className="flex flex-col items-center text-gray-400 hover:text-black">
          <svg
            className="w-6 h-6"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
          </svg>
        </Link>

        {/* Certification Icon */}
        <Link to="/certifications" className="flex flex-col items-center text-gray-400 hover:text-black">
          <svg
            className="w-6 h-6"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
          >
            <path
              d="M28,5H4A2,2,0,0,0,2,7V24a2,2,0,0,0,2,2H18v3a1,1,0,0,0,.53.88,1,1,0,0,0,1-.05L22,28.2l2.45,1.63A1,1,0,0,0,26,29V26h2a2,2,0,0,0,2-2V7A2,2,0,0,0,28,5ZM22.59,21.94a3,3,0,1,1,2.35-2.35A3,3,0,0,1,22.59,21.94ZM24,27.13l-1.45-1a1,1,0,0,0-1.1,0l-1.45,1V23.58a5,5,0,0,0,4,0ZM28,24H26V22a4.93,4.93,0,0,0,.88-4.1A5,5,0,0,0,17,19a4.93,4.93,0,0,0,1,3v2H4V7H28Z"
            />
          </svg>
        </Link>

        {/* Custom Icon (links to the Tech Stack Page) */}
        <Link to="/tech-stack" className="flex flex-col items-center text-gray-400 hover:text-black">
          <CodeIcon />
        </Link>

        {/* Chat Icon (Contact Page) */}
        <Link to="/contact" className="flex flex-col items-center text-gray-400 hover:text-black">
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6"
          >
            <path d="M8 10.5H16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"></path>
            <path d="M8 14H13.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"></path>
            <path
              d="M17 3.33782C15.5291 2.48697 13.8214 2 12 2C6.47715 2 2 6.47715 2 12C2 13.5997 2.37562 15.1116 3.04346 16.4525C3.22094 16.8088 3.28001 17.2161 3.17712 17.6006L2.58151 19.8267C2.32295 20.793 3.20701 21.677 4.17335 21.4185L6.39939 20.8229C6.78393 20.72 7.19121 20.7791 7.54753 20.9565C8.88837 21.6244 10.4003 22 12 22C17.5228 22 22 17.5228 22 12C22 10.1786 21.513 8.47087 20.6622 7"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
            ></path>
          </svg>
        </Link>
      </div>
    </nav>
  );
};

export default BottomNavBar;
