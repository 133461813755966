import React from "react";

const ContactPage: React.FC = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-black text-white font-chakraPetch">
      <div className="container mx-auto px-4 flex justify-between items-start">
        {/* Left Side: Contact Form */}
        <div className="w-1/2">
          <h2 className="text-3xl font-bold mb-4">Need a chat?</h2>
          <form className="space-y-4">
            <div className="flex space-x-4">
              <input
                type="text"
                placeholder="Your Name *"
                className="w-full p-4 bg-gray-800 text-white border border-red-500 rounded"
              />
              <input
                type="email"
                placeholder="Your Email *"
                className="w-full p-4 bg-gray-800 text-white border border-red-500 rounded"
              />
            </div>
            <input
              type="text"
              placeholder="Subject"
              className="w-full p-4 bg-gray-800 text-white rounded"
            />
            <textarea
              placeholder="Message"
              className="w-full p-4 h-32 bg-gray-800 text-white rounded"
            ></textarea>
            <button className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600">
              Submit
            </button>
          </form>
        </div>

        {/* Right Side: Connect with Me Section */}
        <div className="w-1/3 ml-12">
          <h2 className="text-3xl font-bold mb-4">Contact Me:</h2>
          <div>
            <ul className="space-y-4">
              <li className="flex items-center">
                <svg
                  className="w-6 h-6 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-4 0v7h-4v-7a6 6 0 016-6zM2 9h4v12H2V9zm2-4a2 2 0 110-4 2 2 0 010 4z" />
                </svg>
                <a
                  href="https://www.linkedin.com/in/teeislamvfx/"
                  className="hover:underline"
                >
                  Connect On LinkedIn
                </a>
              </li>
            </ul>
          </div>

          <div className="mt-8">
            <h3 className="text-lg font-semibold">Or you can write a mail directly to</h3>
            <a
              href="mailto:thamim@tham.im"
              className="text-blue-500 hover:text-blue-600 underline"
            >
              thamim@tham.im
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
