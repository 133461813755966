import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HeroSection from './components/HeroSection';
import BottomNavBar from './components/BottomNavBar';
import TechStackPage from './components/TechStackPage';
import CertificationsPage from './components/CertificationsPage';
import ContactPage from './components/ContactPage'; // Import ContactPage
import './App.css';

const App: React.FC = () => {
  return (
    <Router>
      <div className="bg-black text-white font-chakraPetch">
        <Routes>
          <Route
            path="/"
            element={
              <HeroSection
                name="Thamim Islam"
                description="Cloud Engineer"
                socialLinks={{
                  github: "https://github.com/thamimvfx",
                  linkedin: "https://www.linkedin.com/in/teeislamvfx/",
                  email: "thamim@tham.im"
                }}
              />
            }
          />
          <Route path="/tech-stack" element={<TechStackPage />} />
          <Route path="/certifications" element={<CertificationsPage />} />
          <Route path="/contact" element={<ContactPage />} />
        </Routes>
        <BottomNavBar />
      </div>
    </Router>
  );
};

export default App;
