import React from 'react';
import profileImage from './profile.jpg';  // Import the image

interface HeroSectionProps {
  name: string;
  description: string;
  socialLinks: {
    github: string;
    linkedin: string;
    email: string;
  };
}

const HeroSection: React.FC<HeroSectionProps> = ({ name, description, socialLinks }) => {
  return (
    <section className="h-screen flex flex-col justify-center bg-black text-white">
      <div className="container mx-auto px-4 flex justify-between items-center">
        {/* Left Side: Text */}
        <div className="max-w-lg">
          <h1 className="text-6xl font-bold">
            Hi, I'm <span className="text-blue-500">{name}</span>
          </h1>
          <p className="mt-4 text-lg">{description}</p>

          {/* Social Links */}
          <div className="mt-6 flex space-x-6">
            <a href={socialLinks.github} target="_blank" rel="noopener noreferrer">
              <svg className="w-6 h-6" fill="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 2C6.477 2 2 6.477 2 12c0 4.418 2.865 8.166 6.839 9.489.5.092.682-.217.682-.483 0-.238-.009-.868-.014-1.703-2.782.605-3.369-1.34-3.369-1.34-.454-1.154-1.11-1.461-1.11-1.461-.908-.62.069-.607.069-.607 1.003.07 1.531 1.031 1.531 1.031.892 1.529 2.341 1.088 2.91.832.091-.647.35-1.088.636-1.338-2.221-.253-4.555-1.111-4.555-4.945 0-1.092.39-1.987 1.029-2.685-.103-.254-.446-1.275.098-2.656 0 0 .84-.27 2.75 1.026A9.575 9.575 0 0112 7.56c.851.004 1.707.114 2.507.334 1.909-1.297 2.748-1.027 2.748-1.027.546 1.382.203 2.403.1 2.657.64.698 1.028 1.592 1.028 2.685 0 3.842-2.337 4.688-4.566 4.935.36.31.679.922.679 1.857 0 1.34-.012 2.422-.012 2.75 0 .268.18.578.688.481A9.999 9.999 0 0022 12c0-5.523-4.477-10-10-10z" />
              </svg>
            </a>
            <a href={socialLinks.linkedin} target="_blank" rel="noopener noreferrer">
              <svg className="w-6 h-6" fill="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-4 0v7h-4v-7a6 6 0 016-6zM2 9h4v12H2V9zm2-4a2 2 0 110-4 2 2 0 010 4z" />
              </svg>
            </a>
            <a href={`mailto:${socialLinks.email}`}>
              <svg className="w-6 h-6" fill="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 4h16v2l-8 5-8-5V4zm16 14H4V8l8 5 8-5v10z" />
              </svg>
            </a>
          </div>

          {/* Resume Button */}
          <div className="mt-6">
            <a href="/resume.pdf" target="_blank" rel="noopener noreferrer">
              <button className="bg-gray-700 text-white px-4 py-2 rounded hover:bg-gray-600">
                Resume
              </button>
            </a>
          </div>
        </div>

        {/* Right Side: Circular Image */}
        <div className="hidden lg:block">
          <div className="w-72 h-72 rounded-full bg-gray-800 overflow-hidden mx-auto lg:mx-0 lg:ml-20">
            <img
              src={profileImage}  // Using the imported image here
              alt="Profile"
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
